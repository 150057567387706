// types
import type { CourseMaterial, OrbitApiMeta } from '@revolutionprep/types'
import type { NitroFetchOptions } from 'nitropack'

// nuxt
import { useNuxtApp } from '#app'

export const useCourseMaterialsStore = defineStore('course-materials', () => {
  // fetch repositories
  const { $courseMaterials } = useNuxtApp()

  // state
  const courseMaterials = ref<CourseMaterial[]>([])

  // actions
  async function index (config?: NitroFetchOptions<string>) {
    const { courseMaterials: _courseMaterials } =
    await $courseMaterials
      .index<{ courseMaterials: CourseMaterial[], meta: OrbitApiMeta}>(
        config
      )
    courseMaterials.value = _courseMaterials || []
    return courseMaterials
  }

  return {
    courseMaterials,
    index
  }
})
